document.addEventListener("DOMContentLoaded", function () {
  /**
   * Features animation using Rive Library @ Hompeage
   *
   */
  // $ represent querySelectorAll inspired from JQuery
  const $ = (selector, event, callback) => {
    let selectors = document.querySelectorAll(selector);
    let len = selectors.length;
    for (let i = 0; i < len; ++i) {
      if (event) {
        selectors[i].addEventListener(event, (e) => callback(e, selectors[i]));
      } else {
        callback(selectors[i]);
      }
    }
  };

  class Carousel {
    constructor(options = null) {
      const carouselWrap = options.carouselWrap || ".carousel_wrap";
      $(carouselWrap, null, () => {
        // For each Carousel in the DOM
        const carousel = document.querySelector(options.carousel);
        if (carousel === null) {
          return;
        }

        const carousel_action = document.querySelectorAll(
          options.carouselActionElement
        );
        const items = document.querySelectorAll(options.carouselChildItem);
        const nextButton = document.querySelector(
          options.carouselActionElement + '[data-action="next"'
        );
        const prevButton = document.querySelector(
          options.carouselActionElement + '[data-action="previous"'
        );

        // Settings
        carousel.style.overflow = options.carouselOverflow || "hidden";
        carousel.dataset.currentslide = "1";
        const show = options.showClass || "d_block";
        const hide = options.hideClass || "d_none";
        const translate_next_slide =
          options.translateNext || "translate_next_slide";
        const translate_prev_slide =
          options.translatePrev || "translate_prev_slide";

        const slidingPerItem = options.slidingPerItem || false;
        const scrollItemsNumber = options.scrollItemsNumber || 1;

        const desktopDisplayedItemsNumber =
          options.desktopDisplayedItemsNumber || 4;
        const tabletDisplayedItemsNumber =
          options.tabletDisplayedItemsNumber || 1;
        const mobileDisplayedItemsNumber =
          options.mobileDisplayedItemsNumber || 1;

        let items_per_slide = desktopDisplayedItemsNumber;
        if (window.matchMedia("screen and (max-width: 768px)").matches) {
          items_per_slide = tabletDisplayedItemsNumber;
        }
        if (window.matchMedia("screen and (max-width: 460px)").matches) {
          items_per_slide = mobileDisplayedItemsNumber;
        }
        const itemslen = items.length;
        if (!itemslen) console.log("Error: There is no items under carousel");

        //Generate and associate each item to own slide
        let counter = 0;
        let first_slide = 1;
        let items_counter = 1;

        for (let i = 0; i < itemslen; ++i) {
          // Create numbring slides dataset depends on settings
          items[i].dataset.slide = items_counter;
          if (slidingPerItem) {
            ++items_counter;
            if (items[i].dataset.slide <= items_per_slide) {
              items[i].classList.add(show);
            } else {
              items[i].classList.add(hide);
            }
          } else {
            if (++counter == items_per_slide) {
              ++items_counter;
              counter = 0;
            }
            if (items[i].dataset.slide == first_slide) {
              items[i].classList.add(show);
            } else {
              items[i].classList.add(hide);
            }
          }
        }

        const last_slide = items[itemslen - 1].dataset.slide;
        if (!carousel) return console.log("Error carousel not exist");
        if (!carousel_action)
          return console.log(
            "Error carousel action not exist: carousel must have an action trigger"
          );
        if (!carousel.dataset.currentslide)
          return console.log(
            "Error: the current slide dataset must be defined in carousel"
          );
        if (carousel.dataset.currentslide == 1) {
          prevButton.classList.add("slider__nav--disabled");
          prevButton.setAttribute("disabled", "disabled");
        }

        const sliding = (direction) => {
          let currentSlide = parseInt(carousel.dataset.currentslide);
          let nextSlide = currentSlide + direction;
          let translate_slide =
            direction === 1
              ? translate_next_slide
              : direction === -1
              ? translate_prev_slide
              : "";

          if (
            nextSlide <= last_slide &&
            nextSlide >= first_slide &&
            nextSlide != currentSlide
          ) {
            prevButton.style.display = "flex";
            nextButton.style.display = "flex";
            carousel.dataset.currentslide = nextSlide;

            if (slidingPerItem) {
              nextSlide += items_per_slide;
              for (let i = 0; i < itemslen; ++i) {
                items[i].classList.remove(
                  hide,
                  show,
                  translate_next_slide,
                  translate_prev_slide
                );
                if (
                  items[i].dataset.slide >=
                    Math.max(1, nextSlide - items_per_slide) &&
                  items[i].dataset.slide <= Math.max(1, nextSlide - 1)
                ) {
                  void items[i].offsetWidth;
                  items[i].classList.add(show, translate_slide);
                } else {
                  void items[i].offsetWidth;
                  items[i].classList.add(hide);
                }
              }
            } else {
              for (let i = 0; i < itemslen; ++i) {
                items[i].classList.remove(
                  hide,
                  show,
                  translate_next_slide,
                  translate_prev_slide
                );
                if (items[i].dataset.slide == nextSlide) {
                  void items[i].offsetWidth;
                  items[i].classList.add(show, translate_slide);
                } else {
                  void items[i].offsetWidth;
                  items[i].classList.add(hide);
                }
              }
            }
          }

          const restOfSlides = itemslen % items_per_slide;

          if (restOfSlides !== 0 && nextSlide == last_slide) {
            for (let i = itemslen - 1; i >= itemslen - items_per_slide; --i) {
              items[i].classList.remove(
                hide,
                show,
                translate_next_slide,
                translate_prev_slide
              );
              void items[i].offsetWidth;
              items[i].classList.add(show, translate_slide);
            }
          }

          nextButton.classList.remove("slider__nav--disabled");
          nextButton.removeAttribute("disabled");
          prevButton.classList.remove("slider__nav--disabled");
          prevButton.removeAttribute("disabled");

          if (nextSlide - 1 == last_slide) {
            nextButton.classList.add("slider__nav--disabled");
            nextButton.setAttribute("disabled", "disabled");
          } else {
          }

          if (nextSlide - 1 == first_slide) {
            prevButton.classList.add("slider__nav--disabled");
            prevButton.setAttribute("disabled", "disabled");
          } else {
          }
        };

        for (let index = 0; index < carousel_action.length; ++index) {
          carousel_action[index].addEventListener("click", () => {
            const action = carousel_action[index].dataset.action;
            if (action == "next") {
              sliding(1);
            } else if (action == "previous") {
              sliding(-1);
            } else if (!isNaN(action)) {
              sliding(action);
            } else {
              return console.log(
                "Error carousel action containes wrong parameters: must have action to 'next' or 'previous' or 'number'"
              );
            }
          });
        }

        let ts, te;
        carousel.addEventListener("touchstart", (e, el) => {
          ts = e.changedTouches[0].clientX;
        });

        carousel.addEventListener("touchend", (e, el) => {
          te = e.changedTouches[0].clientX;
          if (ts > te) {
            sliding(1);
          } else if (ts < te) {
            sliding(-1);
          }
        });
      });
    }
  }
  new Carousel({
    carouselWrap: "#demo",
    carousel: "#demo .slider__wrapper",
    carouselChildItem: "#demo .slider__wrapper .slider__item",
    carouselActionElement: "#demo .nav__buttons .slider__nav",
    carouselOverflow: "hidden",
    slidingPerItem: true,
    scrollItemsNumber: 1,
    desktopDisplayedItemsNumber: 1,
    tabletDisplayedItemsNumber: 1,

    hideClass: "hidden",
    showClass: "unset",
    translateNext: "translate_next_slide",
    translatePrev: "translate_prev_slide",
  });
});
