/**
 * open Modal @ Homepage & Mission
 *
 */
var video = document.querySelector("#videoModal video");

function openModal() {
  document.getElementById("videoModal").style.display = "flex";
  const osanoWidget = document.querySelector(".osano-cm-widget");
  if (osanoWidget) {
    osanoWidget.style.display = "none"; // Hide the Osano widget
  }
  if (video) {
    video.play();
  }
}

function closeModal() {
  document.getElementById("videoModal").style.display = "none";
  if (video) {
    video.pause();
    video.currentTime = 0;
  }
}
