/**
 * Features animation using Rive Library @ Hompeage
 *
 */

document.addEventListener("DOMContentLoaded", function () {
  // Function to initialize a Rive animation
  function initializeRiveAnimation(canvasId, rivFilePath, autoplay) {
    const canvas = document.getElementById(canvasId);
    if (!canvas) {
      return null;
    }

    try {
      const riveAnimation = new rive.Rive({
        src: rivFilePath,
        canvas: canvas,
        autoplay: autoplay,
        stateMachines: "Main",
        onLoad: () => {
          riveAnimation.resizeDrawingSurfaceToCanvas();
        },
      });
      return riveAnimation;
    } catch (error) {
      return null;
    }
  }

  // Initialize the first Rive animations using paths from the localized object.
  initializeRiveAnimation("canvas1", animationPaths.animation1, true); // Desktop animation
  initializeRiveAnimation("canvas4", animationPaths.animation1, true); // Mobile animation, same as the desktop one but using different canvas to prevent conflict

  const featuresContainer = document.querySelector("#features");

  if (featuresContainer) {
    const features = featuresContainer.querySelectorAll(".feature");
    const canvasAll = featuresContainer.querySelectorAll("canvas");
    const toggleButtons = document.querySelectorAll("svg.toggleTabBtn");

    // Add event listener to each toggle button
    toggleButtons.forEach((svg) => {
      svg.addEventListener("click", () => {
        const dataIndex = svg.getAttribute("data-index");
        const feature = featuresContainer.querySelector(`.feature[data-index="${dataIndex}"]`);

        // Toggle .open/.closed for features and canvases
        toggleOpenClass(features, dataIndex);
        toggleOpenClass(canvasAll, dataIndex);
        playRiveAnimation(dataIndex);
      });
    });
  }

  // Function to toggle open/closed classes based on data-index
  const toggleOpenClass = (elements, dataIndex) => {
    elements.forEach((element) => {
      const elementDataIndex = element.getAttribute("data-index");
      if (elementDataIndex === dataIndex) {
        element.classList.remove("closed");
        element.classList.add("open");
      } else {
        element.classList.remove("open");
        element.classList.add("closed");
      }
    });
  };

  const playRiveAnimation = (dataIndex) => {
    if (dataIndex == 1 || dataIndex == 4) {
      initializeRiveAnimation("canvas" + dataIndex, animationPaths.animation1, true);
    } else if (dataIndex == 2 || dataIndex == 5) {
      initializeRiveAnimation("canvas" + dataIndex, animationPaths.animation2, true);
    } else if (dataIndex == 3 || dataIndex == 6) {
      initializeRiveAnimation("canvas" + dataIndex, animationPaths.animation3, true);
    }
  };
});
